import React, { useEffect, useCallback } from 'react'
import clsx from 'clsx'

import { hooks, I18n } from '@front/volcanion'

import { Loader, Route, Router, Routes, Outlet } from '@front/squirtle'

import IvrBar from '@abra/elements/IVR'
import SideBar from '@abra/elements/sidebar'
import TopBar from '@abra/elements/topbar'

import Action from './Action'
import Authentication from './Authentication'
import AuthorizedPackage from './AuthorizedPackage'
import Billing from './Billing'
import Ceiling from './Ceiling/'
import CodePromo from './CodePromo'
import CommercialFormula from './CommercialFormula'
import CommercialPackage from './CommercialPackage'
import Company from './Company'
import CompanyContract from './CompanyContract/'
import CompanyService from './CompanyService/'
import DailyActivity from './DailyActivity'
import Dispatch from './Dispatch'
import Driver from './Driver/'
import Equipment from './Equipment'
import Export from './Export'
import ExportLicense from './ExportLicense'
import ExportOrder from './ExportOrder'
import Group from './Group/'
import Home from './Home'
import Incident from './Incident'
import IncidentHistory from './Incident/History/List'
import Job from './Job/'
import Message from './Message'
import NoShow from './NoShow/'
import Order from './Order'
import Playground from './Playground'
import Poi from './Poi'
import PoiType from './PoiType'
import Pricerule from './Pricerule/'
import RateParameter from './RateParameter/'
import Schedule from './Schedule/'
import Setting from './Setting'
import SpecificParameter from './SpecificParameter/'
import Staff from './Staff'
import Supervision from './Supervision'
import Taxicounter from './Taxicounter/'
import TelephonyProfile from './TelephonyProfile'
import TrafficAlert from './TrafficAlert'
import User from './User/'
import UserOption from './UserOption/'
import Vehicle from './Vehicle'
import VehicleOption from './VehicleOption/'

import './styles.scss'
import { Box, Button, Typography } from '@mui/material'
import { HookUtils } from '@front/volcanion/utils'
import LocalContextProvider from '@front/volcanion/contexts/local'
import { useCurrentAuthorization, useRoleAuthorizations } from '@front/volcanion/hooks'

function getRoleAuthorizations(authorized_pages, role_id) {
  return _.map(authorized_pages, (page) => {
    const authorization = _.find(page.authorizations, ['role', role_id])
    return {
      ..._.omit(page, ['authorizations']),
      ...authorization
    }
  })
}

const PageHandler = ({ suffix }) => {
  const navigate = hooks.useNavigate()
  return (
    <Box display={'flex'} flexDirection={'column'} flex={1} height={'20%'} alignItems={'center'} justifyContent={'center'}>
      <Box display={'flex'} flexDirection={'column'} flex={1} alignItems={'center'} justifyContent={'center'}>
        <Typography color={'secondary'} fontSize={24} fontWeight={'bold'}>{I18n.t(`page_${suffix}.title`)}</Typography>
        <Typography fontSize={20}>{I18n.t(`page_${suffix}.body`)}</Typography>
      </Box>
      <Button variant='contained' onClick={() => navigate('/')}>{I18n.t(`page_${suffix}.button`)}</Button>
    </Box>
  )
}

const RoleRouter = () => {
  const current_authorization = useCurrentAuthorization(true)
  const authorizations = useRoleAuthorizations(true)
  return !!current_authorization?.display ? <Outlet context={{ authorizations }} /> : <PageHandler suffix={'authorization'} />
}
const MemoRoleRouter = React.memo(RoleRouter)

const PrivateRouter = () => {
  return (
    <Routes>
      <Route path='*' element={<PageHandler suffix={'notfound'} />} />
      <Route path='/' element={<MemoRoleRouter />} >
        <Route path='/' exact element={<Home />} />
        <Route path='/adherent/*' element={<Group />} />
        <Route path='/authorizedpackage/*' element={<AuthorizedPackage />} />
        <Route path='/ceiling/*' element={<Ceiling />} />
        <Route path='/client/*' element={<User />} />
        <Route path='/codepromo/*' element={<CodePromo />} />
        <Route path='/commercialformula/*' element={<CommercialFormula />} />
        <Route path='/company/*' element={<Company />} />
        <Route path='/companycontract/*' element={<CompanyContract />} />
        <Route path='/companyservice/*' element={<CompanyService />} />
        <Route path='/daily-activity/*' element={<DailyActivity />} />
        <Route path='/dispatch/*' element={<Dispatch />} />
        <Route path='/driver/*' element={<Driver />} />
        <Route path='/driver-option/*' element={<UserOption />} />
        <Route path='/equipment/*' element={<Equipment />} />
        <Route path='/export/*' element={<Export />} />
        <Route path='/export/license/*' element={<ExportLicense />} />
        <Route path='/export/order/*' element={<ExportOrder />} />
        <Route path='/history/*' element={<Action />} />
        <Route path='/incident' element={<Incident />} />
        <Route path='/job/*' element={<Job />} />
        <Route path='/message/*' element={<Message />} />
        <Route path='/noshow/*' element={<NoShow />} />
        <Route path='/order/*' element={<Order />} />
        <Route path='/package/*' element={<CommercialPackage />} />
        <Route path='/playground/*' element={<Playground />} />
        <Route path='/poi/*' element={<Poi />} />
        <Route path='/poitype/*' element={<PoiType />} />
        <Route path='/pricerule/*' element={<Pricerule />} />
        <Route path='/price-rule/*' element={<Pricerule />} />
        <Route path='/rateparameter/*' element={<RateParameter />} />
        <Route path='/schedule/*' element={<Schedule />} />
        <Route path='/setting/*' element={<Setting />} />
        <Route path='/specificparameter/*' element={<SpecificParameter />} />
        <Route path='/staff/*' element={<Staff />} />
        <Route path='/supervision/*' element={<Supervision />} />
        <Route path='/taxicounter/*' element={<Taxicounter />} />
        <Route path='/telephonyprofile/*' element={<TelephonyProfile />} />
        <Route path='/traffic-alert/*' element={<TrafficAlert />} />
        <Route path='/useroption/*' element={<UserOption />} />
        <Route path='/user-option/*' element={<UserOption />} />
        <Route path='/validation/*' element={<Billing />} />
        <Route path='/vehicle/*' element={<Vehicle />} />
        <Route path='/vehicle-option/*' element={<VehicleOption />} />
        <Route path='/vehicleoption/*' element={<VehicleOption />} />
        <Route path='/incident/history' element={<IncidentHistory />} />
      </Route>
    </Routes>
  )
}
const withDrawer = (Component) => (props) => {
  const [isDrawerOpen] = hooks.useGlobalState('isDrawerOpen')
  // const { CPP_URL } = hooks.useRelayConstants(['CPP_URL'])
  const CPP_URL = null
  const { EXTERNAL_PHONE_SERVICE } = hooks.useRelayConstants(['EXTERNAL_PHONE_SERVICE'])
  const display_ivr = EXTERNAL_PHONE_SERVICE === 'aws'
  const { openNotification } = hooks.useNotification()

  hooks.useSocketEvent('dispatch_forbidden', useCallback(async data => {
    openNotification(data.message, { variant: 'error' })
  }, [openNotification]))

  hooks.useSocketEvent('dispatch_success', useCallback(async data => {
    openNotification(data.message, { variant: 'success' })
  }, [openNotification]))


  return <Component isDrawerOpen={isDrawerOpen} CppURL={CPP_URL} display_ivr={display_ivr} {...props} />
}
const PrivateRoute = ({ isDrawerOpen, CppURL, display_ivr }) => {

  const [authorized_pages, , search_state] = hooks.useModelSearch('page', 'getAuthorized', { default_limit: -1, initial_filter: {}, populate: ['authorizations'] })
  const [user, user_state] = hooks.useActiveUser({ populate: ['auth'] })
  const authorizations = getRoleAuthorizations(authorized_pages, user?.auth?.role)
  return (
    <div className={clsx('root', { ['shift']: isDrawerOpen })}>
      <Router>
        <LocalContextProvider context={{ authorizations }}>
          <Loader isLoading={HookUtils.getLoadingState([search_state, user_state])}>
            <TopBar>{!!display_ivr && !!CppURL && <IvrBar CppURL={CppURL} />}</TopBar>
            <SideBar />
            <PrivateRouter />
          </Loader>
        </LocalContextProvider>
      </Router>
    </div>
  )
}
const DrawerPrivateRoute = React.memo(withDrawer(PrivateRoute))

const withAuth = (Component) => (props) => {
  const isReady = hooks.useReadyStatus()
  const isAuthenticated = hooks.useAuthenticated()
  return <Component isReady={isReady} isAuthenticated={isAuthenticated} {...props} />
}

const Pages = ({ isReady, isAuthenticated }) => {
  const [, setMode] = hooks.useGlobalState('mode')

  useEffect(() => {
    setMode('light')
  }, [])

  return !!isAuthenticated ? <DrawerPrivateRoute /> : <Authentication />
};
const AuthPrivateRoute = React.memo(withAuth(Pages))

export default React.memo(AuthPrivateRoute);
